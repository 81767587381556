<template>
  <div class="main">
    <heads></heads>
    <banners></banners>
    <!-- <islogin /> -->
    <div class="content">
      <div class="cons">
        <div class="sctp-title">图片查重</div>
        <shuoming :smdata="smdata" :srcList="srcList"></shuoming>
        <div class="sctp">
          <el-upload
            drag
            v-model:file-list="fileList"
            class="upload-demo"
            action="https://titletools-api.yuanpingjia.cn/pictureCheck/upload"
            :headers="headersObj"
            list-type="picture"
            :limit="1"
            accept=".jpg,.png"
            :on-exceed="handleExceed"
            :on-error="uploaderr"
            :on-success="uploadsuccess"
            :before-upload="handlePreview"
            :before-remove="handleRemove"
          >
            <el-icon class="el-icon--upload"><upload-filled /></el-icon>
            <div class="el-upload__text">
              拖拽图片到此处或 <em>点击此处</em> 选择图片上传
            </div>
            <template #tip>
              <div class="el-upload__tip">只能上传jpg或png格式图片</div>
            </template>
          </el-upload>
        </div>
        <div class="sctp-info">
          <el-form :model="form" label-width="120px" :label-position="'top'">
            <!-- <el-form-item label="图片名称">
              <el-input v-model="form.pictureName" />
            </el-form-item>
            <el-form-item label="图片描述">
              <el-input v-model="form.describe" type="textarea" />
            </el-form-item> -->
            <el-form-item>
              <el-button type="primary" @click="onSubmit()">立即提交</el-button>
              <el-button @click="comelist">返回列表</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script setup>
import { post } from "@/utils/index";
import { ElMessage } from "element-plus";
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
// 图片
let smdata = reactive([
  { url: "/img/shuoming/05-01.png", tips: "提交图片" },
  { url: "/img/shuoming/05-02.png", tips: "开始查重" },
  { url: "/img/shuoming/05-03.png", tips: "获得结果" },
]);
let srcList = reactive([
  "/img/shuoming/05-01.png",
  "/img/shuoming/05-02.png",
  "/img/shuoming/05-03.png",
]);
const ruleFormRef = ref();
const baseRules = {
  pictureName: [
    { required: true, message: "图片名称不能为空", trigger: "blur" },
  ],
  describe: [{ required: true, message: "图片描述不能为空", trigger: "blur" }],
};
let form = reactive({
  pictureName:'',
  describe:'',
});
// 上传成功后图片地址
let pictureUrl = ref("");
// headers
let headersObj = reactive({
  Authorization: "Bearer " + window.localStorage.getItem("token"),
});
// 文件列表
let fileList = ref([]);
// 上传之前
const handlePreview = (file) => {
  // 此处可以限制大小
  // ElMessage({
  //   message: "图片超出大小",
  //   duration: 1000,
  //   type: 'warning',
  // });
  // return false
};
// 上传成功后
const uploadsuccess = (file) => {
  if (file.code == 200) {
    // console.log(file.result)
    form.pictureName=file.result.fileName
    pictureUrl.value = file.result.url;
  } else if (file.code == 401) {
    ElMessage({
      message: file.message,
      type: "error",
      duration: 1000,
      onClose: () => {
        router.push({
          path: "/login",
          query: {},
        });
      },
    });
  } else {
    ElMessage({
      message: "上传失败",
      duration: 1000,
      type: "error",
    });
  }
};
// 超出限制
const handleExceed = (file) => {
  ElMessage({
    message: "只能上传一张图片",
    duration: 1000,
    type: "warning",
  });
};
// 删除文件
const handleRemove = (uploadFile, uploadFiles) => {
  pictureUrl.value = "";
};

// 提交表单
const onSubmit = () => {
  if (pictureUrl.value == "") {
    ElMessage({
      message: "请选择图片",
      duration: 1000,
      type: "warning",
    });
    return false;
  }

  post(
    "/pictureCheck/add",
    Object.assign(form, { pictureUrl: pictureUrl.value })
  ).then((res) => {
    // console.log(res);
    ElMessage({
      message: "提交成功！",
      duration: 1000,
      onClose: () => {
        comelist();
      },
    });
  });
};

// 返回列表
const comelist = () => {
  router.push({
    path: "/tpcc/list",
    query: {},
  });
};
</script>

<style scoped>
.main {
  background: #fff;
}
.content {
  width: 1200px;
  height: auto;
  margin: auto;
}
.cons {
  width: 1200px;
  margin: auto;
  padding: 30px 0;
}
.sctp-info {
  margin-top: 20px;
}
.sctp-title {
  text-align: center;
  font-size: 24px;
  padding-bottom: 20px;
}
</style>
